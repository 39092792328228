










import AssessmentConsiderationsBlock from '@/components/Controls/ReportBuilder/Blocks/AssessmentConsiderationsBlock.vue'
import AssessmentSummaryBlock from '@/components/Controls/ReportBuilder/Blocks/AssessmentSummaryBlock.vue'
import ComparableSalesDetailsBlock from '@/components/Controls/ReportBuilder/Blocks/ComparableSalesDetailsBlock.vue'
import CoverPageBlock from '@/components/Controls/ReportBuilder/Blocks/CoverPageBlock.vue'
import DisclaimerBlock from '@/components/Controls/ReportBuilder/Blocks/DisclaimerBlock.vue'
import PropertyAssessmentPageBlock from '@/components/Controls/ReportBuilder/Blocks/PropertyAssessmentBlock.vue'
import ValuationFormBlock from '@/components/Controls/ReportBuilder/Blocks/ValuationFormBlock.vue'
import ValuerInsightsBlock from '@/components/Controls/ReportBuilder/Blocks/ValuerInsightsBlock.vue'
import ReportBuilderToolbar from '@/components/Controls/ReportBuilder/ReportBuilderToolbar.vue'
import ReportPage from '@/components/Controls/ReportBuilder/ReportPage.vue'
import { GetReportModel } from 'truemarket-modules/src/models/api/reports'
import { GetBlockBaseModel } from 'truemarket-modules/src/models/api/reports/builder/blocks'
import IReportDataModel from 'truemarket-modules/src/models/api/reports/IReportDataModel'
import { Services } from 'truemarket-modules/src/services'
import { VueConstructor } from 'vue'
import { Component, Vue } from 'vue-property-decorator'

import Components from '../../components/Controls/ReportBuilder/Blocks'

Components.push(ReportPage)

function mapComponentArray (components: VueConstructor<Vue>[]) {
  const map: Record<string, VueConstructor<Vue>> = {}

  for (let i = 0; i < components.length; i++) {
    const c = components[i]

    map[c.name] = c
  }

  return map
}

@Component({
  // components: mapComponentArray(Components)
  components: {
    ReportPage,
    ReportBuilderToolbar,
    ValuationFormBlock,
    CoverPageBlock,
    PropertyAssessmentPageBlock,
    AssessmentConsiderationsBlock,
    ComparableSalesDetailsBlock,
    AssessmentSummaryBlock,
    ValuerInsightsBlock,
    DisclaimerBlock
  }
})
export default class PrintReport extends Vue {
  private report: GetReportModel | null = null;

  private reportData: IReportDataModel | null = null

  private blocks: GetBlockBaseModel[] = []

  mounted () {
    const id = this.$route.params.id

    console.log(id)

    if (id) {
      Services.API.Reports.GetReport(id).then((report) => {
        this.report = report
        if (this.report) {
          Services.API.Reports.GetReportData(this.report.ReportId).then((data) => {
            this.reportData = data.Data
            this.updateBlocks()
          })
        }
      })
    }
  }

  updateBlocks () {
    if (this.report) {
      Services.API.Reports.GetBlocks(this.report.ReportId).then((blocks) => {
        this.blocks = blocks
      })
    }

    setTimeout(() => { window.print() }, 2000)
  }
}
